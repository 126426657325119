import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import {
  Section,
  SectionTitle,
  SectionDescription,
  BreadCrumb,
} from "../components/Section"
import CallNow from "../components/CallNow"
import CategoryCard from "../components/CategoryCard"
import BreakpointUp from "../components/Media/BreakpointUp"

const SectionGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  ${BreakpointUp.lg`
        margin-right: -30px;
        margin-left: -30px; 
    `}
  & p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 26px;
    ${BreakpointUp.lg`
            font-size: 20px;
            line-height: 38px
        `}
  }
`
const SectionLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 120px;
  padding-bottom: 60px;
  ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 30px;
        padding-left: 30px;  
        padding-top:215px;
        padding-bottom:90px;
    `}
  & p {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 26px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
  }
`
const SectionRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #0b619b;
  padding-top: 60px;
  padding-bottom: 60px;
  ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 30px;
        padding-left: 30px;  
    `}
  ${BreakpointUp.xl`
        padding-top:120px;
        padding-bottom:90px;
    `}
`
const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const SectionTable = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;
`
const SectionTableLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 25%;
        max-width: 25%;
    `}

  & h2 {
    margin-bottom: 20px;
  }
  & strong {
    font-size: 20px;
    font-weight: 900;
    color: #000;
    ${BreakpointUp.lg`
            font-size:24px;
        `}
  }
  & p {
    color: #707070;
    font-size: 18px;
    line-height: 28px;
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:34px;
        `}
  }
`
const SectionTableRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 75%;
        max-width: 75%;
    `}
`
const TableStriped = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: #444;
  border-top: 1px solid #dfe3ef;
  border-bottom: 1px solid #dfe3ef;
  ${BreakpointUp.md`
        font-size:18px;
        line-height:28px;
    `}
`
const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dfe3ef;
  &:last-child {
    border-bottom: none;
  }
`
const TableCol = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 10px 5px;
  ${BreakpointUp.md`
        padding:15px 15px;
    `}
  strong {
    color: #000;
    font-weight: 700;
  }
`
const ListInsulation = styled.div`
  padding: 0 15px;
  ${BreakpointUp.lg`
        padding:0 30px;
    `}
  @media (min-width: 1600px) {
    padding: 0 70px;
  }
`
const ListInsulationItem = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0;
  position: relative;
  & .icon {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 0;
    + .text {
      margin-left: 100px;
    }
  }
  & .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }
`

const SectionListGrid = styled.ul`
  margin: 0;
  padding: 0;
`
const SectionItemGrid = styled.li`
  padding: 0;
  margin: 0 0 30px;
  ${BreakpointUp.md`
        margin:0 0 60px;
    `}
`

const Insulation = ({ data, location }) => {
  const pageData = data.contentfulInsulationPage
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <Section pt="0" pb="0" xpt="0" xpb="0" bgColor="#F2F4F9">
        <div className="container">
          <SectionGrid>
            <SectionLeft>
              <h1>{pageData.heroTitle}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData.heroDescription.childMarkdownRemark.html,
                }}
              />
            </SectionLeft>
            <SectionRight>
              <ListInsulation>
                {pageData.features.map(item => (
                  <ListInsulationItem key={v4()}>
                    <span className="icon">
                      <GatsbyImage
                        image={item.gatsbyImageData}
                        alt={item.title}
                      />
                    </span>
                    <span className="text">{item.title}</span>
                  </ListInsulationItem>
                ))}
              </ListInsulation>
            </SectionRight>
          </SectionGrid>
        </div>
      </Section>
      <Section pt="90px" pb="30px" xpt="30px" xpb="0" bgColor="#fff">
        <div className="container">
          <SectionTitle textAlign="left">
            {pageData.insulatedBuildingsSectionTitle}
          </SectionTitle>
          <SectionDescription mb="30px" textAlign="left">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.insulatedBuildingsSectionDescription
                    .childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <SectionListGrid>
            {pageData.insulatedBuildings.map(item => (
              <SectionItemGrid key={v4()}>
                <CategoryCard data={item} />
              </SectionItemGrid>
            ))}
          </SectionListGrid>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left"
      >
        <div className="container">
          <SectionTable>
            <SectionTableLeft>
              <h2>Stats</h2>
              <strong>Prodex Total Insulation</strong>
              <p>
                *R-value 16 (one layer) <br /> *R-value 21.10 (two layers)
              </p>
            </SectionTableLeft>
            <SectionTableRight>
              <TableStriped>
                <TableRow>
                  <TableCol>
                    <strong>Characteristics</strong>
                  </TableCol>
                  <TableCol>
                    <strong>Value Regulation</strong>
                  </TableCol>
                  <TableCol>
                    <strong>Regulation</strong>
                  </TableCol>
                </TableRow>
                {pageData.stats.map(item => (
                  <TableRow key={v4()}>
                    <TableCol>{item.characteristics}</TableCol>
                    <TableCol>{item.value_regulation}</TableCol>
                    <TableCol>{item.regulation}</TableCol>
                  </TableRow>
                ))}
              </TableStriped>
            </SectionTableRight>
          </SectionTable>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionEditor>
            <SectionEditorLeft>
              <h2>{pageData.introSectionTitle}</h2>
            </SectionEditorLeft>
            <SectionEditorRight>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.introSectionDescription.childMarkdownRemark.html,
                }}
              />
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      <CallNow />
    </Layout>
  )
}

export default Insulation

export const pageQuery = graphql`
  query InsulationQuery {
    contentfulInsulationPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      pageName
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      features {
        title
        gatsbyImageData
      }
      insulatedBuildingsSectionTitle
      insulatedBuildingsSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      insulatedBuildings {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData
        }
        buttonLink
        buttonText
      }
      stats {
        characteristics
        regulation
        value_regulation
      }
      introSectionTitle
      introSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
